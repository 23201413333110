import {
  AppstoreOutlined,
  DiffOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { DatePicker, Menu, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { useAPI } from "../SolarProvider";

export const NavBarSolar = () => {
  const { t } = useTranslation();
  const {
    processedAt,
    selectDate,
    setSelectDate,
    handleToggleModal,
    setLoading,
    data,
  } = useAPI();

  const disabledDate = (current) => {
    // Impede a seleção de meses após o mês atual
    return current && current > moment().endOf("month");
  };

  const dateFormat = "YYYY-MM-DD";
  return (
    <Card className="solar-header-controls">
      <Typography.Text>
        Processado em:{" "}
        {moment(processedAt ? processedAt * 1000 : new Date()).format("llll")}
      </Typography.Text>

      <DatePicker
        value={moment(selectDate, dateFormat)}
        onChange={(date) => {
          if (!date) return;
          setSelectDate(new Date(date));
          setLoading(true);
        }}
        format={"MM/YYYY"}
        picker="month"
        placeholder={t("solar.selectMonth")}
        disabledDate={disabledDate}
      />

      <Menu mode="horizontal">
        <Menu.SubMenu
          disabled={!data.general}
          title="Relatórios"
          icon={<DiffOutlined />}
        >
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => handleToggleModal("modalPDF")}
          >
            {t("solarReport.reportPDF")}
          </Menu.Item>
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => handleToggleModal("modalXLS")}
          >
            {t("solarReport.reportXLS")}
          </Menu.Item>
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => handleToggleModal("modalReportManager")}
          >
            {t("reportManager.title")}
          </Menu.Item>
          <Menu.Item
            icon={<AppstoreOutlined />}
            onClick={() => handleToggleModal("modalBillingReport")}
          >
            Financeiro
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item
          icon={<SettingOutlined />}
          onClick={() => handleToggleModal("config")}
        >
          Configurações
        </Menu.Item>
      </Menu>
    </Card>
  );
};
