import React, { useCallback, useEffect, useState } from "react";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatTimestamp } from "../../../../../../../src/utils/functions.js";
import { DateRangeSelector } from "../../../../../../components/DateRangeSelector.js";
import { usePlantContext } from "../../PlantProvider.js";
import { useRefMeterContext } from "../RefMeterProvider";
import { readPowerFactorChart } from "../RefMeterService";
import { useSelector } from "react-redux";
import { COLOR_CHARTS } from "../../../../../../utils/Constants.js";
const moment = require("moment");

const ENUM_ENTRY_RANGE = {
  today: "minutes",
  last24Hours: "minutes",
  last7Days: "days",
  last30Days: "days",
  last12Months: "months",
  "24Hours": "minutes",
  "7D": "days",
  fullMonth: "days",
  fullYear: "months",
};

export const PowerFactorChart = ({
  windowObervable,
  isModal = false,
  viewChart,
}) => {
  const { t } = useTranslation();
  const currentTheme = useSelector((store) => store.theme.currentTheme);

  const { dvcId } = useRefMeterContext();
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [isOneYAxis, setIsOneYAxis] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [start, setStart] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [showChart, setShowChart] = useState(false);
  const [end, setEnd] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [range, setRange] = useState("fullMonth");
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  useEffect(() => {
    if (selectedDate) {
      setStart(moment(selectedDate).startOf("month").format("YYYY-MM-DD"));
      setEnd(moment(selectedDate).endOf("month").format("YYYY-MM-DD"));
    }
  }, [selectedDate]);

  const getChartData = useCallback(
    async (id) => {
      setLoadingChart(true);
      const inductiveName = t("charts.inductive");
      const capacitiveName = t("charts.capacitive");
      const response = await readPowerFactorChart(
        id,
        new Date(start).toString(),
        new Date(end).toString(),
        inductiveName,
        capacitiveName,
        ENUM_ENTRY_RANGE[range]
      );
      if (response) {
        const { data, isOneYAxis, viewChart } = response;
        setChartData(
          data.map((element) => ({
            ...element,
            name: formatTimestamp(element.name, ENUM_ENTRY_RANGE[range]),
          }))
        );
        setIsOneYAxis(isOneYAxis);
        setShowChart(viewChart);
      } else {
        setChartData([]);
        setIsOneYAxis(false);
        setShowChart(false);
      }
      setLoadingChart(false);
    },
    [end, range, start, t]
  );

  useEffect(() => {
    if (dvcId) getChartData(dvcId);
  }, [dvcId, getChartData]);

  const tooltipFormatter = useCallback(
    (value, name, props) => {
      if (
        name === `${t("charts.inductive")}` &&
        props.payload[`${t("charts.inductive")}`] !== 0
      ) {
        const inductiveValue = 1 - props.payload[`${t("charts.inductive")}`];
        return [`${t("charts.inductive")}: ${inductiveValue.toFixed(2)}`];
      } else if (
        name === `${t("charts.capacitive")}` &&
        props.payload[`${t("charts.capacitive")}`] !== 0
      ) {
        const capacitiveValue =
          -1 * (1 + props.payload[`${t("charts.capacitive")}`]);
        return [`${t("charts.capacitive")}: ${capacitiveValue.toFixed(2)}`];
      } else if (name === `${t("charts.inductive")}`) {
        return [`${t("charts.inductive")}: ${t("charts.noData")}`];
      } else if (name === `${t("charts.capacitive")}`) {
        return [`${t("charts.capacitive")}: ${t("charts.noData")}`];
      }
    },
    [t]
  );

  const yAxisTickFormatter = useCallback((value) => {
    let newValue = value - 1;

    if (value < 0) {
      newValue = value + 1;
    }

    return `${(newValue * -1).toFixed(2)}`;
  }, []);

  const hasNoDataOnChart = chartData?.filter((item) => {
    if (
      item[`${t("charts.inductive")}`] === 0 &&
      item[`${t("charts.capacitive")}`] === 0
    ) {
      return true;
    }
    return false;
  }).length === chartData?.length;

  // Função para lidar com o clique nas barras do gráfico
  const handleBarClick = (data) => {
    const clickedData = data; // Captura os dados da barra clicada
    if (ENUM_ENTRY_RANGE[range] === "days") {
      const startDay = moment(clickedData.name, "DD/MM - ddd").startOf("day").toDate();
      const endDay = moment(clickedData.name, "DD/MM - ddd").endOf("day").toDate();

      setRange("24Hours");
      setStart(startDay);
      setEnd(endDay);
    }
    if (ENUM_ENTRY_RANGE[range] === "months") {
      const startMonth = moment(clickedData.name, "MM/YYYY")
        .startOf("month")
        .toDate();
      const endMonth = moment(clickedData.name, "MM/YYYY")
        .endOf("month")
        .toDate();

      setRange("fullMonth");
      setStart(startMonth);
      setEnd(endMonth);
    }
  };

  return viewChart || showChart ? (
    <div className="my-2" style={{ width: "100%" }}>
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
          {t("charts.chartPowerFactor")}
        </CardTitle>
        {!isModal && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>
      <DateRangeSelector
        loading={loadingChart}
        initDate={start}
        finishDate={end}
        setEntrieStartDate={setStart}
        setEntrieEndDate={setEnd}
        entrieRange={range}
        setEntrieRange={setRange}
      >
        <ResponsiveContainer width="100%" height={330}>
          <BarChart data={chartData} options={{}} barCategoryGap={0}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number"
              domain={hasNoDataOnChart ? [-1, 1] : undefined}
              tickFormatter={yAxisTickFormatter} />
            <Tooltip formatter={tooltipFormatter} />
            <Legend style={{ fontSize: "16px" }} />
            <ReferenceLine y={0} stroke="#000" />
            <ReferenceLine
              y={yAxisTickFormatter(-0.92)}
              stroke="red"
              strokeDasharray="3 3"
            />
            <ReferenceLine
              y={yAxisTickFormatter(0.92)}
              stroke="red"
              strokeDasharray="3 3"
              ifOverflow="extendDomain"
            />
            <Bar
              dataKey={`${t("charts.inductive")}`}
              fill={COLOR_CHARTS.peak}
              onClick={handleBarClick}
              cursor={"pointer"}
            />
            <Bar
              dataKey={`${t("charts.capacitive")}`}
              fill={COLOR_CHARTS.offPeak}
              onClick={handleBarClick}
              cursor={"pointer"}
            />
          </BarChart>
        </ResponsiveContainer>
      </DateRangeSelector>
      {toggleModal && (
        <Modal
          isOpen={toggleModal}
          toggle={handleToggleModal}
          size={windowObervable < 1400 ? "lg" : "xl"}
        >
          <ModalBody>
            <PowerFactorChart isModal={true} />
          </ModalBody>
        </Modal>
      )}
    </div>
  ) : null;
};
