import React from "react";

export default function PerformanceRatioBar({value}) {
  return (
    <div className="progress relative">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        style={{
          width: `${value * 100}%`,
        }}
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
      <span
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          fontWeight: "bold",
        }}
      >
        {(value * 100).toFixed(2)}%
      </span>
    </div>
  );
}
