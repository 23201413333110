import { DiffOutlined } from "@ant-design/icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { ConfigProvider, DatePicker, Menu, Table } from "antd";
import enUS from "antd/es/locale/en_US";
import ptBR from "antd/es/locale/pt_BR";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  PRESET,
  useWindowDimensions,
} from "../../../../../../igDashboards/view/hooks/useWindowDimensions";
import SolarReport from "../Report/solarReport";
import MonthlyEnergyData from "./MonthlyEnergyData";
import MonthlyPowerData from "./MonthlyPowerData";
import PerformanceRatio from "./PerformanceRatio";
import Productivity from "./Productivity";
import ResizableTitle from "./ResizableColumn";
import Status from "./Status";

const PlantsTableSort = ({ plantList, loading, maxHeight }) => {
  const { t, i18n } = useTranslation();
  const [columns, setColumns] = useState([
    {
      title: `${t("solar.plant").toUpperCase()}`,
      dataIndex: "plantName",
      key: "plantName",
      width: window.innerWidth < 768 ? 100 : 150,
      fixed: "left",
      sorter: (a, b) => a.plantName.localeCompare(b.plantName),
      render: (_, record) => (
        <Link to={`/project-${record.plantId}-solar`}>{record.plantName}</Link>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "plantStatus",
      key: "plantStatus",
      width: 80,
      sorter: (a, b) => a.plantStatus - b.plantStatus,
      render: (_, record) => <Status plant={record} />,
      align: "center",
    },
    {
      title: `${t("solar.inverters").toUpperCase()}`,
      dataIndex: "plantInverterQuantity",
      key: "plantInverterQuantity",
      align: "center",
      width: 120,
      sorter: (a, b) => a.plantInverterQuantity - b.plantInverterQuantity,
      render: (_, record) =>
        `${record.plantInverterOnline}/${record.plantInverterQuantity} online`,
    },
    {
      title: `${t("solar.dailyEnergy").toUpperCase()} (kWh)`,
      dataIndex: "plantDailyEnergy",
      key: "plantDailyEnergy",
      width: 160,
      align: "right",
      sorter: (a, b) => a.plantDailyEnergy - b.plantDailyEnergy,
      render: (value) => value.toLocaleString(),
    },
    {
      title: `${t("solar.solarCapacity").toUpperCase()} (kWp)`,
      dataIndex: "plantCapacity",
      key: "plantCapacity",
      width: 140,
      align: "right",
      sorter: (a, b) => a.plantCapacity - b.plantCapacity,
      render: (value) => value.toLocaleString(),
    },
    {
      title: `${t("solar.powerLabel").toUpperCase()} (kW)`,
      dataIndex: "plantPower",
      key: "plantPower",
      width: 180,
      align: "center",
      sorter: (a, b) => a.plantPower - b.plantPower,
      render: (_, value) => <MonthlyPowerData data={value} />,
    },
    {
      title: `${t("solar.energyLabel").toUpperCase()} (kWh)`,
      dataIndex: "plantMonthlyEnergy",
      key: "plantMonthlyEnergy",
      width: 180,
      align: "center",
      sorter: (a, b) => a.plantMonthlyEnergy - b.plantMonthlyEnergy,
      render: (_, value) => <MonthlyEnergyData data={value} />,
    },

    {
      title: `${t("solar.capacityFactor").toUpperCase()}`,
      dataIndex: "plantProductivity",
      key: "plantProductivity",
      width: 200,
      sorter: (a, b) => a.plantProductivity - b.plantProductivity,
      render: (_, plant) => <Productivity plant={plant} />,
    },
    {
      title: `${t("solar.irradiation").toUpperCase()} (kWh/m²)`,
      dataIndex: "plantIrradiation",
      key: "plantIrradiation",
      align: "center",
      width: 160,
      sorter: (a, b) => a.plantIrradiation - b.plantIrradiation,
      render: (value) => value.toLocaleString(),
    },
    {
      title: `${t("solar.prIrradiationPercent")}`,
      dataIndex: "plantPRIrradiation",
      key: "plantPRIrradiation",
      align: "center",
      width: 150,
      sorter: (a, b) => a.plantPRIrradiation - b.plantPRIrradiation,
      render: (value) => <PerformanceRatio value={value} />,
    },
    {
      title: `${t("solar.prPvSystPercent")}`,
      dataIndex: "plantPRPvSyst",
      key: "plantPRPvSyst",
      align: "center",
      width: 150,
      sorter: (a, b) => a.plantPRPvSyst - b.plantPRPvSyst,
      render: (value) => <PerformanceRatio value={value} />,
    },
  ]);

  const handleResize =
    (index) =>
    (e, { size }) => {
      setColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };
  const [openReportModal, setOpenReportModal] = useState(false);

  const toggleReportModal = () => {
    setOpenReportModal(!openReportModal);
  };

  const { preset, height } = useWindowDimensions();

  useEffect(() => {
    setColumns([
      {
        title: `${t("solar.plant").toUpperCase()}`,
        dataIndex: "plantName",
        key: "plantName",
        width: window.innerWidth < 768 ? 100 : 150,
        fixed: "left",
        sorter: (a, b) => a.plantName.localeCompare(b.plantName),
        render: (_, record) => (
          <Link to={`/project-${record.plantId}-solar`}>
            {record.plantName}
          </Link>
        ),
      },
      {
        title: "STATUS",
        dataIndex: "plantStatus",
        key: "plantStatus",
        width: 80,
        sorter: (a, b) => a.plantStatus - b.plantStatus,
        render: (_, record) => <Status plant={record} />,
        align: "center",
      },
      {
        title: `${t("solar.inverters").toUpperCase()}`,
        dataIndex: "plantInverterQuantity",
        key: "plantInverterQuantity",
        align: "center",
        width: 120,
        sorter: (a, b) => a.plantInverterQuantity - b.plantInverterQuantity,
        render: (_, record) =>
          `${record.plantInverterOnline}/${record.plantInverterQuantity} online`,
      },
      {
        title: `${t("solar.dailyEnergy").toUpperCase()} (kWh)`,
        dataIndex: "plantDailyEnergy",
        key: "plantDailyEnergy",
        width: 160,
        align: "right",
        sorter: (a, b) => a.plantDailyEnergy - b.plantDailyEnergy,
        render: (value) => value.toLocaleString(),
      },
      {
        title: `${t("solar.solarCapacity").toUpperCase()} (kWp)`,
        dataIndex: "plantCapacity",
        key: "plantCapacity",
        width: 140,
        align: "right",
        sorter: (a, b) => a.plantCapacity - b.plantCapacity,
        render: (value) => value.toLocaleString(),
      },
      {
        title: `${t("solar.powerLabel").toUpperCase()} (kW)`,
        dataIndex: "plantPower",
        key: "plantPower",
        width: 180,
        align: "center",
        sorter: (a, b) => a.plantPower - b.plantPower,
        render: (_, value) => <MonthlyPowerData data={value} />,
      },
      {
        title: `${t("solar.energyLabel").toUpperCase()} (kWh)`,
        dataIndex: "plantMonthlyEnergy",
        key: "plantMonthlyEnergy",
        width: 180,
        align: "center",
        sorter: (a, b) => a.plantMonthlyEnergy - b.plantMonthlyEnergy,
        render: (_, value) => <MonthlyEnergyData data={value} />,
      },

      {
        title: `${t("solar.capacityFactor").toUpperCase()}`,
        dataIndex: "plantProductivity",
        key: "plantProductivity",
        width: 200,
        sorter: (a, b) => a.plantProductivity - b.plantProductivity,
        render: (_, plant) => <Productivity plant={plant} />,
      },
      {
        title: `${t("solar.irradiation").toUpperCase()} (kWh/m²)`,
        dataIndex: "plantIrradiation",
        key: "plantIrradiation",
        align: "center",
        width: 160,
        sorter: (a, b) => a.plantIrradiation - b.plantIrradiation,
        render: (value) => value.toLocaleString(),
      },
      {
        title: `${t("solar.prIrradiationPercent")}`,
        dataIndex: "plantPRIrradiation",
        key: "plantPRIrradiation",
        align: "center",
        width: 150,
        sorter: (a, b) => a.plantPRIrradiation - b.plantPRIrradiation,
        render: (value) => <PerformanceRatio value={value} />,
      },
      {
        title: `${t("solar.prPvSystPercent")}`,
        dataIndex: "plantPRPvSyst",
        key: "plantPRPvSyst",
        align: "center",
        width: 150,
        sorter: (a, b) => a.plantPRPvSyst - b.plantPRPvSyst,
        render: (value) => <PerformanceRatio value={value} />,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className="w-100">
      {openReportModal ? (
        <ModalSolarReport open={openReportModal} toggle={toggleReportModal} />
      ) : null}
      {plantList ? (
        <ConfigProvider locale={i18n.language === "en" ? enUS : ptBR}>
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h4 className="">{`${t("solar.plants")}`}</h4>
              <Menu mode="horizontal">
                <Menu.SubMenu title="Relatórios" icon={<DiffOutlined />}>
                  <Menu.Item
                    icon={<FontAwesomeIcon icon={faFilePdf} />}
                    onClick={toggleReportModal}
                  >
                    {t("solarReport.reportPDF")}
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </CardHeader>
            <CardBody
              className="styleScroll"
              style={{
                padding: 0,
              }}
            >
              <Table
                columns={columns.map((col, index) => ({
                  ...col,
                  onHeaderCell: (column) => ({
                    width: column.width,
                    onResize: handleResize(index),
                  }),
                }))}
                scroll={{
                  y: maxHeight
                    ? maxHeight
                    : preset === PRESET.MOBILE
                    ? 260
                    : preset === PRESET.DESKTOP_HD
                    ? height * 0.15
                    : preset === PRESET.DESKTOP_HD_PLUS
                    ? height * 0.2
                    : preset === PRESET.DESKTOP_FULL_HD
                    ? height * 0.25
                    : height * 0.3,
                }}
                dataSource={plantList}
                size="small"
                pagination={false}
                components={{
                  header: {
                    cell: ResizableTitle,
                  },
                }}
              />
            </CardBody>
          </Card>
        </ConfigProvider>
      ) : false ? (
        <div className="text-center mb-4">
          {" "}
          <Spinner color="primary" />
        </div>
      ) : (
        <p className="mt-3 text-center">{t("devices.noData")}</p>
      )}
    </div>
  );
};

export default PlantsTableSort;

const ModalSolarReport = ({ open, toggle }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDates = (dates) => {
    if (!dates) return;
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };
  const { t } = useTranslation();

  const { data, status, error } = useQuery({
    queryKey: ["generateSolarReport", startDate, endDate],
    queryFn: async () => {
      const response = await axios.get("generateSolarReport", {
        params: {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
      });
      return response.data;
    },
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
    refetchInterval: Infinity,
  });

  return (
    <Modal isOpen={open} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{t("solar.reactPdfModalTitle")}</ModalHeader>

      <ModalBody className="overflow-auto">
        <DatePicker.RangePicker
          value={[startDate, endDate]}
          onChange={handleDates}
          className="mb-3"
          placeholder={[t("solarReport.startDate"), t("solarReport.endDate")]}
          allowClear={false}
          allowEmpty={[false, false]}
          picker="month"
          format={"MMMM - YYYY"}
          disabledDate={(current) => {
            return current && current > new Date();
          }}
          mode={["month", "month"]}
        />
        {!startDate && !endDate ? (
          <div className="text-center">{t("solarReport.selectDates")}</div>
        ) : status === "success" && data && data.info ? (
          <SolarReport data={data} />
        ) : status === "success" && data && data.message ? (
          <div className="text-center">{data.message}</div>
        ) : status === "error" ? (
          <div className="text-center">
            {error.response.data.message ?? t("solarReport.error")}
          </div>
        ) : (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button color="primary" className="mb-1" onClick={toggle} outline>
          {t("solarReport.closeButton")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
