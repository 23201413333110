import React, { useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Button,
  CardHeader,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { usePlantContext } from "../PlantProvider";
import { COLOR_CHARTS } from "../../../../../utils/Constants.js";
import { formatTimestamp } from "../../../../../utils/functions.js";

export const LineChartCarbonEmissionGeneralPlant = ({
  windowObervable,
  projectId,
  isModal = false,
}) => {
  const { t } = useTranslation();
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { chart, loadingChart } = usePlantContext();

  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  const chartData = chart?.arrayConsumption.map((data, index) => ({
    date: formatTimestamp(data.date, "days"),
    carbonEmission: parseFloat(chart?.arrayCarbonEmission[index]?.carbonEmission).toFixed(2),
  }));

  if (loadingChart)
    return (
      <div className="d-flex justify-content-center m-4">
        <Spinner />
      </div>
    );
  
  if (!chart?.arrayConsumption.length && !loadingChart)
    return (
      <CardText tag="h5" className="d-flex justify-content-center m-4">
        {t("charts.noData")}
      </CardText>
    );

  return (
    <>
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag={"h5"}>
          {t("homePlant.carbonEmission")}
        </CardTitle>
        {!isModal && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="carbonEmission"
            name={t("homePlant.carbonEmission")}
            stroke={COLOR_CHARTS.offPeak}
            activeDot={{ r: 8 }}
            fill={COLOR_CHARTS.offPeak}
          />
        </LineChart>
      </ResponsiveContainer>

      {toggleModal && (
        <Modal
          isOpen={toggleModal}
          toggle={handleToggleModal}
          size={windowObervable < 1400 ? "lg" : "xl"}
        >
          <ModalBody
            style={{
              maxHeight: windowObervable < 1400 ? "60vh" : "80vh",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <LineChartCarbonEmissionGeneralPlant
              isModal={true}
              projectId={projectId}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
