import React, { useEffect, useState } from "react";
import { CardHeader, CardBody, CardTitle, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useChartHeight from "../hook";

const AvailabilityChart = ({ month, days, availability }) => {
  const { t } = useTranslation();

  // Definindo os dados para o gráfico
  const data = days.map((day, index) => ({
    date: day.toString().padStart(2, "0"),
    value1: availability[index] ? availability[index] * 100 : 0,
  }));

  const height = useChartHeight()

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart layout="horizontal" data={data}>
      <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="category" dataKey="date" />
        <YAxis
          type="number"
          domain={[0, 100]}
          tickFormatter={(value) => `${value} %`}
          width={50}
        />
        <Tooltip formatter={(value) => `${value.toFixed(2)} %`} />
        <Legend formatter={() => `${t("solar.availability")} (%)`} />
        <Bar
          dataKey="value1"
          name={`${t("solar.availability")} (%)`}
          fill="#8884d8"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const Availability = ({ month, projectId }) => {
  const { t } = useTranslation();

  const { data, status } = useQuery({
    queryKey: [
      "readSolarMonthlyEnergy",
      { projId: projectId, monthRef: month },
    ],
    queryFn: () =>
      axios
        .get("/readSolarMonthlyEnergy", {
          params: {
            projId: projectId,
            monthRef: month,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });
  return (
    <>
      <CardHeader>
        <CardTitle tag="h5">{t("solar.plantAvailability")}</CardTitle>
      </CardHeader>

      <CardBody>
        {status === "loading" ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <AvailabilityChart
            month={month}
            days={data.monthlyEnergy.days}
            availability={data.monthlyEnergy.availability}
          />
        )}
      </CardBody>
    </>
  );
};

export default Availability;
