import React, { useEffect, useState } from "react";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import { formatTimestamps } from "../../../../../../utils/functions.js";
import { usePlantContext } from "../../PlantProvider.js";
import { useRefMeterContext } from "../RefMeterProvider";
import { readBarChartDemand } from "../RefMeterService";
import { DateRangeSelector } from "../../../../../../components/DateRangeSelector.js";
import { useSelector } from "react-redux";
import { COLOR_CHARTS } from "../../../../../../utils/Constants.js";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const ENUM_ENTRY_RANGE = {
  today: "minutes",
  last24Hours: "minutes",
  last7Days: "days",
  last30Days: "days",
  last12Months: "months",
  "24Hours": "minutes",
  "7D": "days",
  fullMonth: "days",
  fullYear: "months",
};

export const BarChartDemand = ({
  windowObervable,
  isModal = false,
  origin,
  viewChart,
}) => {
  const { t } = useTranslation();
  const currentTheme = useSelector((store) => store.theme.currentTheme);

  const { dvcId } = useRefMeterContext();
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [chart, setChart] = useState(null);
  const [start, setStart] = useState(
    moment(selectedDate).startOf("month").toDate()
  );
  const [end, setEnd] = useState(moment(selectedDate).endOf("day").toDate());
  const [range, setRange] = useState("fullMonth");
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  useEffect(() => {
    if (selectedDate) {
      setStart(moment(selectedDate).startOf("month").format("YYYY-MM-DD"));
      setEnd(moment(selectedDate).endOf("month").format("YYYY-MM-DD"));
      setRange("fullMonth");
    }
  }, [selectedDate]);

  useEffect(() => {
    if (dvcId) getChartData();
  }, [dvcId, start, end]);

  const getChartData = async () => {
    setLoadingChart(true);
    const chart = await readBarChartDemand(
      dvcId,
      start,
      end,
      ENUM_ENTRY_RANGE[range]
    );
    if (chart) setChart(chart);
    if (!chart) setChart(null);
    setLoadingChart(false);
  };

  // Transformando os dados para o formato que o Recharts espera
  const chartData = chart
    ? chart.totalDemandTimestamp.map((timestamp, index) => ({
        timestamp: formatTimestamps([timestamp], ENUM_ENTRY_RANGE[range])[0],
        offPeak: chart.offPeakDemand[index],
        peak: chart.peakDemand[index],
        contractedPeak: chart.contractedPeakDemand[index],
        contractedOffPeak: chart.contractedOffPeakDemand[index],
      }))
    : [];

  const handleBarClick = (data, index) => {
    const clickedData = data; // Captura os dados da barra clicada
    if (ENUM_ENTRY_RANGE[range] === "days") {
      const startDay = moment(clickedData.timestamp, "DD/MM - ddd").startOf("day").toDate();
      const endDay = moment(clickedData.timestamp, "DD/MM - ddd").endOf("day").toDate();

      setRange("24Hours");
      setStart(startDay);
      setEnd(endDay);
    }
    if (ENUM_ENTRY_RANGE[range] === "months") {
      const startMonth = moment(clickedData.timestamp, "MM/YYYY")
        .startOf("month")
        .toDate();
      const endMonth = moment(clickedData.timestamp, "MM/YYYY")
        .endOf("month")
        .toDate();

      setRange("fullMonth");
      setStart(startMonth);
      setEnd(endMonth);
    }
  };

  const renderCustomBar = (props) => {
    const { fill, x, y, width, height, value } = props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          onClick={() => handleBarClick(props, value)}
          style={
            ENUM_ENTRY_RANGE[range] !== "minutes"
              ? { cursor: "pointer" }
              : { cursor: "default" }
          }
        />
      </g>
    );
  };

  return (
    <>
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
          {t("charts.chartDemand")}{" "}
        </CardTitle>
        {!isModal && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>
      <DateRangeSelector
        loading={loadingChart}
        initDate={start}
        finishDate={end}
        setEntrieStartDate={setStart}
        setEntrieEndDate={setEnd}
        entrieRange={range}
        setEntrieRange={setRange}
      >
        {viewChart || chart?.viewChart ? (
          <ResponsiveContainer width="100%" height={330}>
            <ComposedChart data={chartData}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="timestamp" />
              <YAxis />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                }}
                formatter={(value) => value.toLocaleString()}
                cursor={{ strokeDasharray: "3 3" }}
              />
              <Legend />
              <Bar
                dataKey="offPeak"
                barSize={20}
                fill={COLOR_CHARTS.offPeak}
                name={t("charts.offPeak")}
                shape={renderCustomBar}
              />
              <Bar
                dataKey="peak"
                barSize={20}
                fill={COLOR_CHARTS.peak}
                name={t("charts.peak")}
                shape={renderCustomBar}
              />
              <Line
                type="monotone"
                dataKey="contractedPeak"
                stroke="#ff7300"
                name={t("charts.contractPeak")}
                strokeDasharray="3 3"
              />
              <Line
                type="monotone"
                dataKey="contractedOffPeak"
                stroke="#82ca9d"
                name={t("charts.contractOffPeak")}
                strokeDasharray="3 3"
              />
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <p>{t("charts.noData")}</p>
        )}
      </DateRangeSelector>
      {toggleModal && (
        <Modal
          isOpen={toggleModal}
          toggle={handleToggleModal}
          size={windowObervable < 1400 ? "lg" : "xl"}
        >
          <ModalBody>
            <BarChartDemand isModal={true} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
