import { DatePicker } from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const dateFormat = "DD/MM/YYYY";

const ENUM_ENTRY_RANGE = {
  today: "minutes",
  last24Hours: "minutes",
  last7Days: "days",
  last30Days: "days",
  last12Months: "months",
  "24Hours": "minutes",
  "7D": "days",
  fullMonth: "days",
  fullYear: "months",
};

/**
 * DateRangeSelector component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @param {boolean} props.loading - Indicates if the component is in a loading state.
 * @param {Date} props.initDate - The initial date for the date range.
 * @param {function} props.setEntrieStartDate - The function to set the start date of the date range.
 * @param {function} props.setEntrieEndDate - The function to set the end date of the date range.
 * @param {function} props.setEntrieRange - The function to set the range of the date entries.
 * @param {Array<string>} [props.notShowFilters=[]] - The filters to exclude from the component.
 * @returns {JSX.Element} The DateRangeSelector component.
 */
export const DateRangeSelector = ({
  children,
  loading,
  initDate,
  finishDate,
  setEntrieStartDate,
  setEntrieEndDate,
  entrieRange,
  setEntrieRange,
  notShowFilters = [],
}) => {
  const { t } = useTranslation();

  const currentDate = moment().endOf("day").toDate();

  const applyDateRange = (range) => {
    let newStartDate = null;
    let newEndDate = null;

    switch (range) {
      case "today":
        newStartDate = moment().startOf("day").toDate();
        newEndDate = moment().endOf("day").toDate();
        break;
      case "24Hours":
        newEndDate = moment(finishDate).endOf("day").toDate();
        newStartDate = moment(finishDate).startOf("day").toDate();
        newStartDate = moment(finishDate).isAfter(currentDate)
        ? moment(currentDate).startOf("day").toDate()
        : moment(finishDate).startOf("day").toDate();
        break;
      case "last24Hours":
        newEndDate = moment().toDate();
        newStartDate = moment().subtract(24, "hours").toDate();
        break;
      case "7D":
        newStartDate = moment(finishDate)
          .subtract(7, "days")
          .startOf("day")
          .toDate();
        break;
      case "last7Days":
        newEndDate = moment().endOf("day").toDate();
        newStartDate = moment().subtract(7, "days").startOf("day").toDate();
        break;
      case "last30Days":
        newEndDate = moment().endOf("day").toDate();
        newStartDate = moment().subtract(30, "days").startOf("day").toDate();
        break;
      case "fullMonth":
        newStartDate = moment(finishDate).startOf("month").toDate();
        break;
      case "last12Months":
        newEndDate = moment().endOf("day").toDate();
        newStartDate = moment()
          .subtract(11, "months")
          .startOf("month")
          .toDate();
        break;
      case "fullYear":
        newStartDate = moment(finishDate).startOf("year").toDate();
        break;
      default:
        newStartDate = moment(finishDate)
          .subtract(7, "days")
          .startOf("day")
          .toDate();
    }

    if (newStartDate) {
      setEntrieStartDate(newStartDate);
    }

    if (newEndDate) {
      newEndDate = moment(newEndDate).isAfter(currentDate)
        ? currentDate
        : newEndDate;
      setEntrieEndDate(newEndDate);
    }

    setEntrieRange(range);
  };

  const handleChangeRange = (direction) => {
    let newStartDate = moment(initDate);
    let newEndDate = moment(finishDate);

    switch (entrieRange) {
      case "custom":
        const rangeDuration = newEndDate.diff(newStartDate);

        newStartDate =
          direction === "next"
            ? newStartDate.add(rangeDuration, "milliseconds")
            : newStartDate.subtract(rangeDuration, "milliseconds");

        newEndDate =
          direction === "next"
            ? newEndDate.add(rangeDuration, "milliseconds")
            : newEndDate.subtract(rangeDuration, "milliseconds");

        break;
      case "today":
        newStartDate =
          direction === "next"
            ? moment(newEndDate).add(1, "day").startOf("day")
            : moment(newEndDate).subtract(1, "day").startOf("day");

        newEndDate =
          direction === "next"
            ? moment(newStartDate).endOf("day")
            : moment(newStartDate).endOf("day");

        break;
      case "24Hours":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(24, "hours")
            : moment(newEndDate).subtract(24, "hours");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).startOf("day")
            : moment(newEndDate).startOf("day");

        break;
      case "last24Hours":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(24, "hours")
            : moment(newEndDate).subtract(24, "hours");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(24, "hours")
            : moment(newEndDate).subtract(24, "hours");

        break;
      case "7D":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(7, "days")
            : moment(newEndDate).subtract(7, "days");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(7, "days")
            : moment(newEndDate).subtract(7, "days");

        break;
      case "last7Days":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(7, "days")
            : moment(newEndDate).subtract(7, "days");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(7, "days")
            : moment(newEndDate).subtract(7, "days");

        break;
      case "last30Days":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(30, "days")
            : moment(newEndDate).subtract(30, "days");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(30, "days")
            : moment(newEndDate).subtract(30, "days");

        break;
      case "fullMonth":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(1, "month").endOf("month")
            : moment(newEndDate).subtract(1, "month").endOf("month");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).startOf("month")
            : moment(newEndDate).startOf("month");

        break;
      case "last12Months":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(12, "months")
            : moment(newEndDate).subtract(12, "months");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(12, "months").startOf("month")
            : moment(newEndDate).subtract(12, "months").startOf("month");

        break;
      case "fullYear":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(1, "year").endOf("year")
            : moment(newEndDate).subtract(1, "year").endOf("year");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).startOf("year")
            : moment(newEndDate).startOf("year");

        break;
      default:
        break;
    }

    newStartDate = moment(newStartDate).isAfter(currentDate)
      ? currentDate
      : newStartDate.toDate();
    newEndDate = moment(newEndDate).isAfter(currentDate)
      ? currentDate
      : newEndDate.toDate();

    setEntrieStartDate(newStartDate);
    setEntrieEndDate(newEndDate);
  };

  const formattedStartDate = moment(initDate).format("DD/MM/YYYY - ddd");
  const formattedEndDate = moment(finishDate).format("DD/MM/YYYY - ddd");

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Row>
        <Col className="button-group" style={{ width: "fit-content" }}>
          <Row className="m-0">
            {[
              "today",
              "last24Hours",
              "last7Days",
              "last30Days",
              "last12Months",
              "24Hours",
              "7D",
              "fullMonth",
              "fullYear",
            ]
              .filter((e) => !notShowFilters.includes(e))
              .map((e) => (
                <Button
                  key={e}
                  style={
                    entrieRange === e
                      ? {
                          color: "white",
                          backgroundColor: "#3b7ddd",
                          border: "none",
                        }
                      : {
                          color: "black",
                          backgroundColor: "transparent",
                          border: "none",
                        }
                  }
                  onClick={() => applyDateRange(e)}
                >
                  {t(`dateRangeSelector.${e}`)}
                </Button>
              ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            gap: "2px",
          }}
        >
          <ArrowLeftCircle
            onClick={() => handleChangeRange("prev")}
            style={{ cursor: "pointer" }}
          />
          <div>
            {ENUM_ENTRY_RANGE[entrieRange] === "minutes" ? (
              <DatePicker
                value={moment(formattedStartDate, dateFormat)}
                onChange={(event) => {
                  const date = new Date(event);
                  if (date) {
                    setEntrieStartDate(moment(date).startOf("day"));
                    setEntrieEndDate(moment(date).endOf("day"));
                  }
                }}
                maxDate={currentDate}
                clearIcon={null}
                format={"DD/MM/YYYY - ddd"} 
              />
            ) : (
              <RangePicker
                value={[
                  moment(formattedStartDate, dateFormat),
                  moment(formattedEndDate, dateFormat),
                ]}
                onChange={(dates) => {
                  if (dates) {
                    const [start, end] = dates;
                    setEntrieStartDate(start.startOf("day").toDate());
                    setEntrieEndDate(end.endOf("day").toDate());
                  }
                }}
                clearIcon={null}
                format={dateFormat}
              />
            )}
          </div>
          <ArrowRightCircle
            onClick={() => handleChangeRange("next")}
            style={{ cursor: "pointer" }}
          />
        </Col>
      </Row>

      {loading ? (
        <Row
          className="d-flex align-items-center justify-content-center h-25"
          style={{ minHeight: "120px" }}
        >
          <Spinner />
        </Row>
      ) : (
        children
      )}
    </Container>
  );
};
