import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Badge,
  CardHeader,
  CardTitle,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

/*** PACOTE DE COMUNICAÇÃO COM API ***/
import axios from "axios";
import {
  PRESET,
  useWindowDimensions,
} from "../../../../igDashboards/view/hooks/useWindowDimensions";
import ModalDeviceVar from "../../ModalDeviceVar";
import { DevicesModal } from "../ModalDevice";

const DevicesTable = ({ devices, projId, activeTab, allData }) => {
  const { t } = useTranslation();
  const [selectedDevice, setSelectedDevice] = useState(
    devices.length ? devices[0].id : null
  );
  const [searchbar, setSearchbar] = useState("");
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [deviceDatails, setDeviceDatails] = useState(false);
  const [color, setColor] = useState("");
  const [device, setDevice] = useState(null);
  const [deviceVars, setDeviceVars] = useState([]);

  let allDataSolar = allData;

  function openModal(modal) {
    setDeviceDatails(modal);
  }

  function closeModal() {
    setDeviceDatails(false);
  }

  useEffect(() => {
    setFilteredDevices(
      devices.filter((item) => {
        return item.deviceName
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            searchbar
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      })
    );
  }, [searchbar, devices]);

  const { height, preset } = useWindowDimensions();

  const TOP = 300;

  const maxHeight =
    preset === PRESET.MOBILE
      ? 250
      : height > 900
      ? height - TOP - 185
      : height - TOP - 88;
  return (
    <Row sm={12}>
      <Col sm={12}>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            {t("solar.deviceList")}
          </CardTitle>
        </CardHeader>
        <div
          className="d-flex align-items-center justify-content-start"
          style={{
            paddingBottom: "10px",
            marginRight: "22px",
            marginLeft: "12px",
          }}
        >
          <Search width={36} height={16} className="text-muted" />
          <Input
            type="text"
            placeholder="Buscar"
            aria-label="Search"
            // className="form-control-lite"
            value={searchbar}
            onChange={(e) => setSearchbar(e.target.value)}
          />
        </div>
        <ListGroup flush style={{ overflowY: "auto", height: maxHeight }}>
          {filteredDevices.map((element) => (
            <ListGroupItem
              className={
                "cursor-pointer" +
                (selectedDevice === element.id ? " bg-light" : "")
              }
              key={element.id}
              onClick={(e) => {
                e.preventDefault();
                setSelectedDevice(element.id);
                if (element.color) {
                  openModal("solar");
                  setColor(element.color);
                } else {
                  axios
                    .get("/readVariables", {
                      params: {
                        id: element.id,
                      },
                    })
                    .then((res) => {
                      setDeviceVars(res.data);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  openModal("others");
                  setDevice(element);
                }
              }}
            >
              <div className="ms-2 me-auto">
                <div>{element.deviceName}</div>
                <div style={{ display: "flex" }}>
                  <div
                    className="font-weight-bold"
                    style={{ marginRight: "10px", width: "50%" }}
                  >
                    {element.model}
                  </div>
                  <div style={{ width: "50%" }}>
                    {element.color ? (
                      <Badge
                        className="badge-pill mr-1 mb-1"
                        style={{
                          float: "right",
                          background:
                            element.color === "green"
                              ? "green"
                              : element.color === "red"
                              ? "red"
                              : element.color === "orange"
                              ? "orange"
                              : element.color === "gray"
                              ? "gray"
                              : null,
                        }}
                      >
                        {element.color === "green"
                          ? "On Grid"
                          : element.color === "red"
                          ? "Off Grid"
                          : element.color === "orange"
                          ? "Stand By"
                          : element.color === "gray"
                          ? "OffLine"
                          : null}
                      </Badge>
                    ) : (
                      <Badge
                        className="badge-pill mr-1 mb-1"
                        style={{
                          float: "right",
                          background:
                            element.status === 0
                              ? "green"
                              : element.status === 1
                              ? "orange"
                              : element.status === 2
                              ? "gray"
                              : null,
                        }}
                      >
                        {element.status === 0
                          ? "Online"
                          : element.status === 1
                          ? "Offline"
                          : element.status === 2
                          ? "Sem comunicação"
                          : null}
                      </Badge>
                    )}
                  </div>
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
        {deviceDatails === "solar" && !!selectedDevice ? (
          <DevicesModal
            device={selectedDevice}
            open={deviceDatails === "solar"}
            toggle={closeModal}
            allData={allDataSolar}
            state={color}
          />
        ) : null}
        {deviceDatails === "others" && !!selectedDevice ? (
          <ModalDeviceVar
            open={deviceDatails === "others"}
            toggle={closeModal}
            device={device}
            devices={devices}
            deviceVars={deviceVars}
            deviceID={selectedDevice}

          />
        ) : null}
      </Col>
    </Row>
  );
};

export default DevicesTable;
