import setHours from "date-fns/setHours";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { fetchGeneralData, fetchListUcs } from "./GasService";

export const GasContext = createContext();

export const GasModuleProvider = ({ activeTab, projectId, children }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentDate = new Date();

  const [searchReport, setSearchReport] = useState(false);
  const [selectedMonthRef, setSelectMonthRef] = useState(
    setHours(currentDate, currentDate.getHours() - 1)
  );
  const [generalData, setGeneralData] = useState({
    generalConsumptionPerDay: [],
  });

  const [loadingUcList, setLoadingUcList] = useState(true);
  const [loadingGeneralData, setLoadingGeneralData] = useState(true);
  const [ucList, setUCList] = useState([]);

  const formattedDate = (date) => {
    const newDate = new Date(date * 1000);
    const processedAtDate =
      newDate.getDate().toString().padStart(2, "0") +
      "/" +
      (newDate.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      newDate.getFullYear() +
      " " +
      newDate.getHours().toString().padStart(2, "0") +
      ":" +
      newDate.getMinutes().toString().padStart(2, "0");
    return processedAtDate;
  };

  const handleChangeDate = (date) => {
    setSelectMonthRef(date);
  };

  const readGeneralData = useCallback(async () => {
    const { data, status } = await fetchGeneralData({
      projectId,
      selectedMonthRef,
      searchReport
    });
    if (status === 200) {
      setGeneralData(data);
    }
    setLoadingGeneralData(false);
  }, [projectId, searchReport, selectedMonthRef]);

  const readUCsByProject = useCallback(async () => {
    setLoadingUcList(true);
    const { data, status } = await fetchListUcs({ projectId });
    if (status === 200) {
      setUCList(data.ucs);
    }
    setLoadingUcList(false);
  }, [projectId]);

  useEffect(() => {
    Promise.all([readUCsByProject()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, projectId]);

  useEffect(() => {
    Promise.all([readGeneralData()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, projectId, selectedMonthRef]);
  console.log(`GasModuleProvider`, { projectId, activeTab, children });
  return (
    <GasContext.Provider
      value={{
        projectId,
        selectedMonthRef,
        setSelectMonthRef,
        formattedDate,
        handleChangeDate,
        readUCsByProject,
        ucList,
        generalData,
        setGeneralData,
        loadingUcList,
        loadingGeneralData,
        searchReport,
        setSearchReport,
      }}
    >
      {children}
    </GasContext.Provider>
  );
};

export function useGasModuleAPI() {
  const context = useContext(GasContext);

  if (!context) {
    throw new Error("useGasModule must be used within an GasProvider");
  }

  return context;
}
