/****** CRIAÇÃO DE ROTAS ******/
/**************************************************************
 * Mantida conforme o template                                *
 * Modificações realizadas:                                   *
 * - Rotas para a Landing Page comentadas.                    *
 * - Configuração de diretório inicial ("/") para a Dashboard *
 * - Verificação de estado de Login e existência de Token     *
 **************************************************************/

import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import dashboardRoutes, { auth as authRoutes, allRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/igAuth/Page404";
import SignIn from "../pages/igAuth/SignIn";
import ResetPassword from "../pages/igAuth/ResetPassword";

import ScrollToTop from "../components/ScrollToTop";

import bg1 from "../assets/background/background-1.jpg";
import bg2 from "../assets/background/background-2.jpg";
import bg3 from "../assets/background/background-3.jpg";
import bg4 from "../assets/background/background-4.jpg";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import enUS from "antd/es/locale/en_US";
import ptBR from "antd/es/locale/pt_BR";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

/****** ROTA PADRÃO ******/
const DefaultRoute = () => {
  const backgrounds = [bg1, bg2, bg3, bg4];

  const getRandomBackground = () => {
    const randomIndex = Math.floor(Math.random() * backgrounds.length);
    return backgrounds[randomIndex];
  };

  const background = getRandomBackground();

  /*** LEITURA DE ESTADO DE LOGIN E TOKEN
   * SALVOS NO LOCAL STORAGE ***/
  var isLoggedIn = localStorage.getItem("isLoggedIn");

  /*** VERIFICAÇÃO DE ESTADO DE LOGIN ***/
  if (isLoggedIn === "true") {
    return (
      <Switch>
        {/*** Redireciona "/" para a Página Inicial ***/}
        <Route exact path="/">
          {localStorage.getItem("userType") === "3" ? (
            <Redirect
              to={`/project-${localStorage.getItem(
                "projectId"
              )}-${localStorage.getItem("module")}`}
            />
          ) : (
            <Redirect to="/home" />
          )}
        </Route>
        {/*** Conforme template ***/}
        {/* {childRoutes(DashboardLayout, dashboardRoutes)} */}
        {localStorage.getItem("userType") === "1"
          ? childRoutes(DashboardLayout, allRoutes)
          : childRoutes(DashboardLayout, dashboardRoutes)}
        {childRoutes(AuthLayout, authRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    );
  } else {
    /*** Se não verificado login, redireciona para página de login
     * ou redefinição de senha ***/
    return (
      <Switch>
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <Route
            exact
            path="/auth/reset-password"
            render={() => (
              <AuthLayout>
                <ResetPassword />
              </AuthLayout>
            )}
          />
          <Route
            render={() => (
              <AuthLayout>
                <SignIn />
              </AuthLayout>
            )}
          />
        </div>
      </Switch>
    );
  }
};

const Routes = () => {
  const { i18n } = useTranslation();

  return (
    <ConfigProvider locale={i18n.language === "en" ? enUS : ptBR}>
      <Router>
        <ScrollToTop>
          <Switch>
            {/*** EXECUTA ROTA PADRÃO ***/}
            <DefaultRoute />
          </Switch>
        </ScrollToTop>
      </Router>
    </ConfigProvider>
  );
};

export default Routes;
