import { faEye, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BatteryCharging, Check, Triangle, Wind } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { DataTable } from "../../../../components/DataTable";
import NotFoundPage from "./404";
import { ConsumerUnit } from "./ConsumerUnit";
import { ConsumerUnitProvider } from "./ConsumerUnit/ConsumerUnitProvider";
import { ConsumptionSharing } from "./ConsumptionSharing";
import { ConsumptionSharingProvider } from "./ConsumptionSharing/ConsumptionSharingProvider";
import { PlantProvider, usePlantContext } from "./PlantProvider";
import { RefMeter } from "./RefMeter";
import { RefMeterProvider } from "./RefMeter/RefMeterProvider";
import { BarChartDemand } from "./RefMeter/components/BarChartDemand";
import { MapMeters } from "./RefMeter/components/MapMeters";
import { PowerFactorChart } from "./RefMeter/components/PowerFactorChart";
import { BarChartConsumptionGeneralPlant } from "./components/BarChartConsumptionGeneralPlant";
import DoughnutChartDevicesStatus from "./components/DoughnutChartDevicesStatus";
import { LineChartCarbonEmissionGeneralPlant } from "./components/LineChartCarbonEmissionGeneralPlant";
import { ModalDeleteMeter } from "./components/ModalDeleteMeter";
import { ModalNewMeter } from "./components/ModalNewMeter";
import { NavBarEnergy } from "./components/NavBarEnergy";

export const EnergyModuleWithProvider = () => {
  const paramsRoute = useParams();
  const [pathRoute, setPathRoute] = useState(paramsRoute);

  useEffect(() => {
    setPathRoute(paramsRoute);
  }, [paramsRoute]);

  return (
    <PlantProvider>
      <ConsumptionSharingProvider>
        <RefMeterProvider projId={paramsRoute.id}>
          <ConsumerUnitProvider deviceId={paramsRoute.refId}>
            <Plant pathRoute={pathRoute} paramsRoute={paramsRoute} />
          </ConsumerUnitProvider>
        </RefMeterProvider>
      </ConsumptionSharingProvider>
    </PlantProvider>
  );
};

export const Plant = ({ pathRoute, paramsRoute }) => {
  const { t } = useTranslation();
  const { plant } = usePlantContext();

  const [windowObservable, setWindowObservable] = useState();
  const [showSearchInput, setShowSearchInput] = useState(false);

  const {
    projectId,
    objectEnergy,
    filterMeters,
    setFilterMeters,
    params,
    selectedDate,
    loadingData,
  } = usePlantContext();

  const location = useLocation();
  const currentPath = location.pathname;

  //NOTE: Filtra quais chaves serão utilizadas na coluna
  const keyObject = Object.keys(
    filterMeters?.length
      ? { ...filterMeters[0], actions: "actions" }
      : { none: "none" }
  ).filter(
    (key) => key !== "projId" && key !== "latitude" && key !== "longitude"
  );

  //NOTE: Keys nas quais não quero apresentar em tela.
  const headerColumnsNotIncluded = [
    "lastComm",
    "alarm",
    "totalPeakConsumption",
    "totalOffPeakConsumption",
    "totalConsumption",
  ];

  //NOTE: Criar objeto de coluna com as keys que recebo de resposta no back
  const columns = keyObject
    ?.filter((key) => !headerColumnsNotIncluded?.includes(key))
    .map((key) => ({
      field: key,
      headerName: t(`homePlant.${key}`),
    }));

  const organizingLines = filterMeters?.map((e, index) => {
    return Object.assign({}, ...keyObject?.map((key) => ({ [key]: e[key] })));
  });
  //NOTE: Cria as linhas exibidas nas tabelas.
  //NOTE: Status: trata o status conforme necessário para exibir em tela
  const displayLines = organizingLines?.map((e, index) => {
    return {
      ...e,
      id: index + 1,
      status: (
        <span
          className={
            !e?.lastComm
              ? "badge bg-secondary"
              : e?.alarm === 0
              ? "badge bg-success"
              : "badge bg-dandger"
          }
        >
          {!e.lastComm
            ? t("homePlant.deviceNeverCommunicated")
            : e?.alarm === 0
            ? t("homePlant.deviceWithoutAlarm")
            : t("homePlant.deviceWithAlarm")}
        </span>
      ),
      refMeter: e?.refMeter && (
        <Check
          color="white"
          style={{
            backgroundColor: "green",
            borderRadius: "50%",
            padding: "5px",
          }}
        />
      ),
      actions: e.refMeter ? (
        <>
          <UncontrolledTooltip target={"view-meter"}>
            {t("homePlant.view")}
          </UncontrolledTooltip>

          <Link
            to={`/project-${projectId}-energy/meter/${
              e.device
            }?name=${e.description?.replace(/\s+/g, "-")}`}
          >
            <FontAwesomeIcon icon={faEye} fixedWidth id={"view-meter"} />
          </Link>
        </>
      ) : (
        <>
          <Link
            to={`/project-${projectId}-energy/uc/${
              e.device
            }?name=${e.description?.replace(/\s+/g, "-")}`}
          >
            <FontAwesomeIcon icon={faEye} fixedWidth id={"view-meter"} />
          </Link>
          <UncontrolledTooltip target={"delete-meter"}>
            {t("homePlant.deleteMeter")}
          </UncontrolledTooltip>
          <FontAwesomeIcon
            onClick={() => deleteMeter(e)}
            icon={faTrash}
            fixedWidth
            id={"delete-meter"}
          />
        </>
      ),
    };
  });

  const [isModalNewMeterOpen, setIsModalNewMeterOpen] = useState(false);
  const [meter, setMeter] = useState(false);
  const [isModalDeleteMeterOpen, setIsModalDeleteMeterOpen] = useState(false);

  function deleteMeter(meter) {
    setMeter(meter);
    setIsModalDeleteMeterOpen(true);
  }

  function toggleModalNewMeter() {
    setIsModalNewMeterOpen(!isModalNewMeterOpen);
  }

  function toggleModalDeleteMeter() {
    setIsModalDeleteMeterOpen(!isModalDeleteMeterOpen);
  }

  useEffect(() => {
    function handleResize() {
      setWindowObservable(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWindowObservable]);

  return (
    <>
      {params === 404 ? (
        <NotFoundPage />
      ) : loadingData ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          {/* <SubMenuGeneral
            plant={plant}
            path={currentPath.split("/")[2]}
            backgroundColor={windowObservable >= 1280 ? "no-white" : "white"}
          /> */}

          {pathRoute?.refId ? (
            <>
              {pathRoute?.refName === "meter" && <RefMeter />}
              {pathRoute?.refName === "uc" && <ConsumerUnit />}
              {pathRoute?.refName === "sharing" && <ConsumptionSharing />}
            </>
          ) : plant ? (
            <>
              <NavBarEnergy plant={plant} path={currentPath.split("/")[2]} />
              <div className="dashboard_energy_module__plant">
                <div className="dashboard_layout">
                  <div className="cards__info">
                    {/*NOTE: Cards exibidos */}
                    {Object.keys(objectEnergy)?.map((e, index) => {
                      // Exibindo cards de valores totais em tela através das keys do objeto recebido pelo backend
                      if (
                        e === "meters" ||
                        e === "ucs" ||
                        e === "valueByRefMeter" ||
                        e === "totalCarbonEmission"
                      )
                        return <React.Fragment key={index}></React.Fragment>;

                      return (
                        <div>
                          <Card
                            key={index}
                            style={{
                              width: "100%",
                              minHeight: "170px",
                              borderRadius: "4px",
                              marginBottom: "0",
                            }}
                          >
                            <CardHeader style={{ paddingBottom: 0 }}>
                              <Row>
                                <Col className="mt-0">
                                  <CardTitle tag="h5">
                                    {t(`homePlant.${e}`)}
                                  </CardTitle>
                                </Col>
                                <Col className="col-auto">
                                  <div
                                    style={{ width: "32px", height: "32px" }}
                                  >
                                    <div className="avatar-title rounded-circle bg-primary-dark">
                                      <BatteryCharging
                                        className="feather align-middle"
                                        style={{
                                          width: "18px",
                                          height: "18px",
                                          marginLeft: "2px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody
                              style={{
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: `center`,
                              }}
                            >
                              {objectEnergy[e] ? (
                                <div
                                  className="display-5 text-center"
                                  style={{ fontSize: "20px", color: "#203A45" }}
                                >
                                  {objectEnergy[e] + " kWh"}
                                </div>
                              ) : (
                                t("homePlant.none")
                              )}
                            </CardBody>
                            <CardFooter>
                              <CardText style={{ fontSize: "12px" }}>
                                Valor referente ao mês:{" "}
                                {moment(selectedDate).format("MM/YYYY")}
                              </CardText>
                            </CardFooter>
                          </Card>
                        </div>
                      );
                    })}

                    {params?.preferences?.includes("carbonEmission") && (
                      <>
                        {/* Card de emissão de carbono */}
                        {objectEnergy["totalCarbonEmission"] && (
                          <div>
                            <Card
                              style={{
                                width: "100%",
                                borderRadius: "4px",
                                minHeight: "170px",
                                marginBottom: "0",
                              }}
                            >
                              <CardHeader style={{ paddingBottom: 0 }}>
                                <Row>
                                  <Col className="mt-0">
                                    <CardTitle tag="h5">
                                      {t(`homePlant.totalCarbonEmission`)}
                                    </CardTitle>
                                  </Col>
                                  <Col className="col-auto">
                                    <div
                                      style={{ width: "32px", height: "32px" }}
                                    >
                                      <div className="avatar-title rounded-circle bg-primary-dark">
                                        <Wind
                                          className="feather align-middle"
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                            marginLeft: "2px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody
                                style={{
                                  padding: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: `center`,
                                }}
                              >
                                <div
                                  className="display-5 text-center"
                                  style={{ fontSize: "20px", color: "#203A45" }}
                                >
                                  {parseFloat(
                                    objectEnergy["totalCarbonEmission"]
                                  ).toFixed(2) + " tco2"}
                                </div>
                              </CardBody>
                              <CardFooter>
                                <CardText style={{ fontSize: "12px" }}>
                                  {t("homePlant.totalCarbonEmission")}
                                </CardText>
                              </CardFooter>
                            </Card>
                          </div>
                        )}

                        {/* Card de compensação */}
                        <div>
                          <Card
                            style={{
                              width: "100%",
                              borderRadius: "4px",
                              minHeight: "170px",
                              marginBottom: "0",
                            }}
                          >
                            <CardHeader
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: 0,
                              }}
                            >
                              <CardTitle tag="h5">
                                {t("homePlant.compensation")}
                              </CardTitle>
                              <div style={{ width: "32px", height: "32px" }}>
                                <div className="avatar-title rounded-circle bg-primary-dark">
                                  <Triangle
                                    className="feather align-middle"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                    }}
                                  />
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody
                              className="p-0"
                              style={{
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: `center`,
                              }}
                            >
                              <div
                                className="display-5 text-center"
                                style={{ fontSize: "20px", color: "#203A45" }}
                              >
                                {Math.round(
                                  parseFloat(
                                    objectEnergy["totalCarbonEmission"]
                                  ).toFixed(2) * 7
                                )}
                              </div>
                            </CardBody>
                            <CardFooter>
                              <CardText style={{ fontSize: "12px" }}>
                                {t("homePlant.treesNeededForOffset")}
                              </CardText>
                            </CardFooter>
                          </Card>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="charts__container">
                    {/* NOTE: Exibindo gráfico de consumo geral da planta */}
                    <div className="chart_container">
                      <Card>
                        <BarChartConsumptionGeneralPlant
                          windowObservable={windowObservable}
                          projectId={projectId}
                        />
                      </Card>
                    </div>

                    {params?.preferences?.includes("demand") && (
                      <div className="chart_container">
                        <Card>
                          <BarChartDemand
                            windowObservable={windowObservable}
                            dvcId={objectEnergy?.refMeter}
                            origin="generalPlant"
                          />
                        </Card>
                      </div>
                    )}

                    {params?.preferences?.includes("powerFactor") && (
                      <div className="chart_container">
                        <Card>
                          <PowerFactorChart
                            windowObservable={windowObservable}
                            dvcId={objectEnergy?.refMeter}
                            viewChart={true}
                          />
                        </Card>
                      </div>
                    )}

                    {params?.preferences?.includes("carbonEmission") && (
                      <div className="chart_container">
                        <Card>
                          <LineChartCarbonEmissionGeneralPlant
                            windowObservable={windowObservable}
                            projectId={projectId}
                          />
                        </Card>
                      </div>
                    )}
                  </div>
                </div>
                <div className="info__footer">
                  {params?.preferences?.includes("devicesInfo") && (
                    <div className="info__footer__devices_info">
                      <div>
                        <Card style={{ height: "288px", minHeight: "280px" }}>
                          <CardHeader>
                            <CardTitle
                              tag="h5"
                              style={{
                                paddingBottom: "0px",
                                marginBottom: "0px",
                              }}
                            >
                              {t("homePlant.statusDevice")}
                            </CardTitle>
                          </CardHeader>
                          <CardBody>
                            <DoughnutChartDevicesStatus />
                          </CardBody>
                        </Card>
                      </div>
                      <div>
                        <Card
                          style={{
                            width: "100%",
                          }}
                        >
                          <MapMeters
                            windowObservable={windowObservable}
                            style={{
                              minHeight:
                                windowObservable >= 992
                                  ? "calc(100% - 0.5em)"
                                  : "calc(100% - 0.5em)",
                            }}
                            dvcId={objectEnergy?.refMeter}
                            filterMeters={filterMeters}
                            projectId={projectId}
                          />
                        </Card>
                      </div>
                    </div>
                  )}

                  {/* NOTE: Exibindo tabela de Medidores */}
                  <div>
                    <Card className="p-3 w-100" style={{ marginRight: "12px" }}>
                      <CardTitle className="mb-4" tag="h5">
                        {t("homePlant.meterTableList")}
                      </CardTitle>
                      <Row className="d-flex align-items-center justify-content-between m-0">
                        <div>
                          {showSearchInput ? (
                            <Input
                              style={{
                                width: "150px",
                                borderRadius: "4px",
                                marginBottom: "4px",
                              }}
                              placeholder={t("homePlant.search")}
                              onChange={({ target }) => {
                                const entrie = target.value;

                                if (!entrie) {
                                  setFilterMeters(objectEnergy?.meters);
                                  return;
                                }

                                setFilterMeters((prev) =>
                                  prev.filter((obj) =>
                                    String(obj.description).includes(entrie)
                                  )
                                );
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="cursor-pointer"
                              icon={faSearch}
                              fixedWidth
                              onClick={() => {
                                setShowSearchInput(true);
                              }}
                            />
                          )}
                        </div>

                        <div
                          className="justify-content-end cursor-pointer"
                          onClick={() => setIsModalNewMeterOpen(true)}
                        >
                          <Button
                            color="primary"
                            style={{ float: "right", marginTop: "-31px" }}
                          >
                            {t("homePlant.addMeter")}
                          </Button>

                          {isModalNewMeterOpen && (
                            <ModalNewMeter
                              open={isModalNewMeterOpen}
                              toggle={toggleModalNewMeter}
                              projId={paramsRoute.id}
                              selectedDate={selectedDate}
                            />
                          )}

                          {isModalDeleteMeterOpen && (
                            <ModalDeleteMeter
                              open={isModalDeleteMeterOpen}
                              toggle={toggleModalDeleteMeter}
                              meter={meter}
                              projId={paramsRoute.id}
                            />
                          )}
                        </div>
                      </Row>

                      <DataTable columns={columns} rows={displayLines} />
                    </Card>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
