/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import {
  formatTimestamps,
  getStartEndMonth,
} from "../../../../../../../src/utils/functions.js";
import { usePlantContext } from "../../PlantProvider.js";
import { useRefMeterContext } from "../RefMeterProvider";
import { readBarChartReactiveConsumed } from "../RefMeterService";
import { DateRangeSelector } from "../../../../../../components/DateRangeSelector.js";
import { COLOR_CHARTS } from "../../../../../../utils/Constants.js";
const moment = require("moment");

export const BarChartReactiveConsumed = ({
  windowObervable,
  isModal = false,
  viewChart,
}) => {
  const { t } = useTranslation();

  const { dvcId } = useRefMeterContext();
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [chart, setChart] = useState(null);
  const [start, setStart] = useState(
    moment(selectedDate).startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(
    moment(selectedDate).endOf("month").format("YYYY-MM-DD")
  );
  const [range, setRange] = useState("days");
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  useEffect(() => {
    if (dvcId) getChartData();
  }, [dvcId, start, end]);

  useEffect(() => {
    if (selectedDate) {
      setStart(moment(selectedDate).startOf("month").toDate());
      setEnd(moment(selectedDate).endOf("day").toDate());
      setRange("fullMonth");
    }
  }, [selectedDate]);

  const getChartData = async () => {
    setLoadingChart(true);
    const chart = await readBarChartReactiveConsumed(dvcId, start, end, range);
    setChart(chart);
    setLoadingChart(false);
  };

  const data = {
    labels: chart
      ? formatTimestamps(chart.totalKwDemandReTimestamp, range)
      : [],
    datasets: [
      {
        label: t("charts.offPeak"),
        data: chart?.totalOffPeakDemandReactive,
        borderWidth: 1,
        backgroundColor: COLOR_CHARTS.offPeak,
        fill: true,
      },
      {
        label: t("charts.peak"),
        data: chart?.totalPeakDemandReactive,
        borderWidth: 1,
        backgroundColor: COLOR_CHARTS.peak,
        fill: true,
      },
    ],
  };

  const options = {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        padding: 30,
        fontSize: 14,
      },
    },
  };

  return (
    //NOTE: Só exibe se existir a estrutura de energia do driver ou estrutura de energia personalizada
    viewChart || chart?.viewChart ? (
      <>
        <CardHeader className="d-flex align-content-center justify-content-between">
          <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
            {t("charts.chartReactiveConsumed")}
          </CardTitle>
          {!isModal && (
            <Button
              className="bg-transparent border-0"
              id={"button-maximize-dashboard"}
              onClick={handleToggleModal}
            >
              <Maximize2 size={16} color="grey" />
              <UncontrolledTooltip target={"button-maximize-dashboard"}>
                {t("public.expand")}
              </UncontrolledTooltip>
            </Button>
          )}
        </CardHeader>
        <DateRangeSelector
          loading={loadingChart}
          initDate={selectedDate}
          setEntrieStartDate={setStart}
          setEntrieEndDate={setEnd}
          setEntrieRange={setRange}
        >
          <Bar data={data} options={options} />
        </DateRangeSelector>
        {toggleModal && (
          <Modal
            isOpen={toggleModal}
            toggle={handleToggleModal}
            size={windowObervable < 1400 ? "lg" : "xl"}
          >
            <ModalBody>
              <BarChartReactiveConsumed isModal={true} />
            </ModalBody>
          </Modal>
        )}
      </>
    ) : null
  );
};
