import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatEnergy } from "../../../../../../../utils/functions";

const MAX_HEIGHT = 230;

const Productivity = () => {
  const { t } = useTranslation();
  const [height, setHeight] = useState(350);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;

      if(window.innerWidth <= 768) {
        setHeight(200);
        return;
      }
      if(window.innerWidth <= 1366) {
        setHeight(250);
        return;
      }
      const calcHeight = windowHeight - (windowHeight * 0.15) - 370;

      const cardHeight = calcHeight > MAX_HEIGHT ? MAX_HEIGHT : calcHeight ;
      setHeight(cardHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    data,
    isLoading,
  } = useQuery({
    queryKey: ["readSolarProductivity"],
    queryFn: async () => {
      const { data } = await axios.get("/readSolarProductivity");
      return data;
    },
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 15, // 15 minutos
  });

  if (isLoading || !data) {
    return (
      <Card>
        <CardHeader>
          <h4>{`${t("solar.productivity")}`} </h4>
        </CardHeader>
        <CardBody>
          <Spinner color="primary" />
        </CardBody>
      </Card>
    );
  }


  return (
    <Card>
      <CardHeader>
        <h4>{`${t("solar.productivity")}`} </h4>
      </CardHeader>
      <CardBody
        style={{
          maxHeight: height,
          overflowY: "auto",
          padding: "0px 10px",
          height: '100%'
        }}
      >
        {/* Envolva o gráfico em um contêiner com altura máxima e scroll vertical */}
        <ResponsiveContainer width="100%" height={data.energy.length * 50}>
          <BarChart
            layout="vertical"
            width={500}
            height={data.energy.length * 50} // Altura dinâmica baseada no número de dados
            data={data.energy}
            bar
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" 
                tickFormatter={formatEnergy}
            />
            <YAxis dataKey="name" type="category" width={150} />
            <Tooltip 
                formatter={formatEnergy}
            />
            <Legend verticalAlign="top" />
            <Bar dataKey="plantMonthlyEnergy" fill="#82ca9d" name="Energia gerada" />
            <Bar dataKey="plantProjectedEnergyIrradiation" fill="#ffc658" name="Energia Projetada (Irradiação)" />
            <Bar dataKey="plantProjectedEnergyPvSyst" fill="#ff7758" name="Energia Projetada (PvSyst)" />
          </BarChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  );
};

export default Productivity;
