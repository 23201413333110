import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmog } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

const AvoidedEmissions = ({ avoidedCO2, loading, loadingModal }) => {
  const { t } = useTranslation();

  // condição para exibir a grandeza
  if (avoidedCO2) {
    var avoidedAndUnit = (avoidedCO2 <= 999) 
      ? avoidedCO2.toFixed(1).replace(".", ",") + " kg" 
      : (avoidedCO2/1000).toFixed(1).replace(".", ",") + " t";
  }

  return (
    <Row style={{minWidth: "147px"}}>
      <Col sm="12" style={{paddingRight:"0px"}}>
        <Card style={{maxHeight:"117px", height: "117px", marginBottom: 25}}>
          <CardBody style={{padding: "15px"}}>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">{t('solar.avoided')}<br></br>{t('solar.emissions')}</CardTitle>
              </Col>
              <Col className="col-auto" style={{paddingLeft:"0px"}}>
                <div style={{width:"26px",height:"26px"}}>
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    <FontAwesomeIcon icon={faSmog} fixedWidth className="align-middle" />
                  </div>
                </div>
              </Col>
            </Row>
            {(loadingModal)
              ? <div className="text-center mb-4"><Spinner /></div>
              : (loading)
                ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                : (avoidedCO2)
                  ? <h1 className="display-5 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{avoidedAndUnit} CO₂</h1>
                  : <p className="text-center">Não há dados</p>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>

  );
}   

export default AvoidedEmissions;